import {Header} from "../components/Header";

// @ts-ignore
import Image1 from "../assets/images/gold.png";
// @ts-ignore
import Image2 from "../assets/images/design.png";
// @ts-ignore
import Image3 from "../assets/images/web.jpeg";
import {Card} from "../components/Card";


// <img src={Image3} width="450rem" height="250rem" style={{objectFit: 'cover', position: 'absolute', right: '-1rem', top: '-2rem' }} />

export const Homepage = () => {

    return (
        <Header>
            <>
                <div className="container-fluid p-5">
                    <div className="row">
                        <div className="col-12 col-lg-12 col-xl-6" id="aboutUs">
                            <p className="text__primary text-uppercase font-weight-bold m-0" style={{ letterSpacing: '3px' }}>About us</p>
                            <h3 className="text-uppercase font-weight-bold m-0">
                                REDEFINING BUSINESS SOLUTIONS & COMMUNICATION</h3>
                            <p className="m-0 text-secondary">We are a consulting company that taps into different markets to help you handle your needs. We always look forward to serving our clients with expanding their company or helping create a business idea from start to finish.</p>
                            <a href="#!" className="btn btn__primary rounded-pill mt-2">Contact us</a>
                        </div>
                        <div className="col-12 col-lg-12 col-xl-6">
                            <div  className="m-auto" style={{ position: 'relative', top: '4rem', width: '32rem', right: '6rem' }}>
                                <div style={{ border: '5px solid #3bafed', zIndex: 3, width: '15rem', height: '15rem', position: 'relative', left: '12.5rem', bottom: '1rem' }}></div>
                                <img src={Image2} width="450rem" height="250rem" style={{objectFit: 'cover', position: 'relative', left: '7.5rem', bottom: '15rem', zIndex: 1 }} />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="bg-dark pb-5 m-0" id="consulting">
                    <h3 className="text-uppercase pt-5 text-center text-light">
                        <span className="font-weight-lighter">What </span>
                        <span className="font-weight-bold">we Provide</span>
                    </h3>
                    <div className="divider" style={{ width: '5rem', backgroundColor: '#3bafed', marginBottom: '1rem' }}></div>

                    <p className="w-50 text-center m-auto text-light pb-3">We do consignments in our AVALON 360 SHOP. <br />
                        Import & export, commodities like gold, silver, diamonds and other Commodities. Collectibles, Web applications, Mobile App, Game Development, Unity, Printing & Design services. </p>

                    <div className="mt-4 container m-auto row">
                        <div className="col-12 col-lg-4 m-auto pb-3">
                            <Card
                                className="bg-dark border-light"
                                title={"Avalon 360"}
                                icon={<i className="fa fa-users fa-2x" />}
                            >
                                <a className="text-light" href="https://www.ebay.com/str/barrelboxdrop">Avalon 360 Shop</a>
                            </Card>
                        </div>
                        <div className="col-12 col-lg-4 m-auto pb-3">
                            <Card
                                className="bg-dark border-light"
                                title={"Printing and Design"}
                                icon={<i className="fa fa-users fa-2x" />}
                            >
                                <p></p>
                            </Card>
                        </div>
                        <div className="col-12 col-lg-4 m-auto pb-3">
                            <Card
                                className="bg-dark border-light"
                                title={"Web/ App Development"}
                                icon={<i className="fa fa-users fa-2x" />}
                            >
                                <p>Web Development/ App Development</p>
                            </Card>
                        </div>
                        <div className="col-12 col-lg-4 m-auto pb-3">
                            <Card
                                className="bg-dark border-light"
                                title={"Game Development"}
                                icon={<i className="fa fa-users fa-2x" />}
                            >
                                <p></p>
                            </Card>
                        </div>
                        <div className="col-12 col-lg-4 m-auto pb-3">
                            <Card
                                className="bg-dark border-light"
                                title={"Shipping"}
                                icon={<i className="fa fa-users fa-2x" />}
                            >
                                <p>Shipiing Import/Export (commodities)</p>
                            </Card>
                        </div>
                        <div className="col-12 col-lg-4 m-auto pb-3">
                            <Card
                                className="bg-dark border-light"
                                title={"Buy & Sell"}
                                icon={<i className="fa fa-users fa-2x" />}
                            >
                                <p>Buy & Sell Gold, Silver, Diamonds</p>
                            </Card>
                        </div>
                    </div>
                </div>

                <div className="pb-5 m-0">
                    <h3 className="text-uppercase pt-5 text-center">
                        <span className="font-weight-lighter">Why Avalon 360</span>
                        <span className="font-weight-bold"> Enterprise?</span>
                    </h3>
                    <div className="divider" style={{ width: '5rem', backgroundColor: '#3bafed', marginBottom: '1rem' }}></div>

                    <p className="w-50 text-center m-auto pb-3">We are a one stop shop, we have integrity and connections around the world to fulfill commodities orders, expand your business to Dubai or Asia. </p>

                    <div className="mt-4 container m-auto row">
                        <div className="col-12 col-lg-4 m-auto pb-3">
                            <Card
                                className="bg-light border-dark text-dark"
                                title={"eBay"}
                                icon={<i className="fa fa-users fa-2x" />}
                            >
                                <a href="https://www.ebay.com/sch/i.html?_ssn=avalon-360-enterprise&store_name=barrelboxdrop&_oac=1&_trksid=p4429486.m3561.l49496">Link for the Avalon shop</a>
                            </Card>
                        </div>
                    </div>
                </div>

                <div className="bg-gray" id="contactUs">
                    <div className="container-fluid row">
                        <div className="col-12 col-lg-6 p-0 m-0">
                            <img src={Image1} width="100%" height="400rem" className="pt-5 pb-5 pr-6" style={{objectFit: 'cover', paddingLeft: '5rem' }} />
                        </div>
                        <div className="col-12 col-lg-6 pb-5 pl-0 contact" style={{ paddingTop: '5rem' }}>
                            <div className="card card-body" style={{ minHeight: '15rem' }}>
                                <h2 className="font-weight-bold">Contact Info:</h2>
                                <p className="m-0">Phone Business U.S.A.- +1-954-588-3166</p>
                                <p>Phone Business Dubai:. +971 50 419 3020</p>

                                <p className="m-0">Email Dubai:</p>
                                <a href="mailto:Avalon360Enterprice@protonmail.com" className="m-0">Avalon360Enterprice@protonmail.com</a>
                                <p className="m-0">
                                    Email U.S.A. -
                                    <a href="mailto:Avalon360Enterprice@gmail.com">Avalon360Enterprice@gmail.com</a>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        </Header>
    )
}