import React from "react";
import {Navbar} from "../Navbar";

export const Header = ({ children }: { children: React.ReactNode }) => {

    return (
        <>
            <Navbar />

            <div className="curve">
                <div className="curve__text">
                    <h5 className="text-uppercase m-0">Any Location, any application</h5>
                    <h1 className="m-0 font-weight-bold text-uppercase">Connecting the</h1>

                    <h1 className="m-0 font-weight-bold text-uppercase">
                        <div className="divider divider__start" />
                        World
                        <div className="divider divider__end" />
                    </h1>

                    <a href="#!" className="btn btn__primary mr-3 rounded-pill">Get started</a>
                    <a href="#!" className="btn btn--white rounded-pill">Learn more</a>
                </div>
            </div>
            <nav className="more">
                <div className="text-center">
                    <div className="btn btn__curved btn__curved--bottom">
                        <i className="fas fa-mouse"></i>
                    </div>
                </div>

                {children}
            </nav>


            <footer className="footer">
                <div className="container mt-5">
                    <div className="input-group w-50 m-auto">
                        <input type="text" className="form-control" placeholder="E-Mail"
                               aria-label="E-Mail" aria-describedby="basic-addon2" />
                            <div className="input-group-append">
                                <button className="btn btn-outline-info" type="button">
                                    <i className="fa fa-user"></i>
                                </button>
                            </div>
                    </div>

                    <div className="mt-5 row">
                        <div className="col-12 col-md-6 col-lg-3 m-auto text-left pb-3">
                            <h5 className="font-weight-bold">
                                <a href="mailto:Avalon360Enterprice@protonmail.com" className="text-light">JV Partnership</a>
                            </h5>
                        </div>
                        <div className="col-12 col-md-6 col-lg-3 m-auto text-left pb-3">
                            <h5 className="font-weight-bold">
                                <a href="mailto:Avalon360Enterprice@protonmail.com" className="text-light">Investor relations</a>
                            </h5>
                        </div>
                        <div className="col-12 col-md-6 col-lg-3 m-auto text-left pb-3">
                            <h5 className="font-weight-bold">
                                <a href="mailto:Avalon360Enterprice@protonmail.com" className="text-light">Marketing</a>
                            </h5>
                        </div>
                        <div className="col-12 col-md-6 col-lg-3 m-auto text-left pb-3">
                            <h5 className="font-weight-bold">
                                <a href="mailto:Avalon360Enterprice@protonmail.com" className="text-light">Consignments</a>
                            </h5>
                        </div>
                    </div>

                    <div className="mt-5 mb-5">
                        <p className="mb-1">Socialmedia</p>

                        <div className="d-flex mb-5 justify-content-center">
                            <div className="flex-column">
                                <a href="https://www.facebook.com/profile.php?id=61556165065067&mibextid=ZbWKwL">
                                <span className="fa-stack fa-lg">
                                  <i className="fa fa-circle fa-stack-2x"></i>
                                  <i className="fa-brands fa-facebook-f fa-stack-1x fa-inverse"></i>
                                </span>
                                </a>
                            </div>

                            <div className="flex-column">
                                <a href="https://www.instagram.com/avalon360enterprise?igsh=MWN6OTM1djV0YWNrag==">
                                <span className="fa-stack fa-lg">
                                  <i className="fa fa-circle fa-stack-2x"></i>
                                  <i className="fa-brands fa-instagram fa-stack-1x fa-inverse"></i>
                                </span>
                                </a>
                            </div>
                            <div className="flex-column">
                                <a href="https://pin.it/2g4Kc0Lvl">
                                <span className="fa-stack fa-lg">
                                  <i className="fa fa-circle fa-stack-2x"></i>
                                  <i className="fa-brands fa-pinterest fa-stack-1x fa-inverse"></i>
                                </span>
                                </a>
                            </div>

                            <div className="flex-column">
                                <a href="https://x.com/avalon360e?t=qumyGvTUJ7qDhoBMyJUxGw&s=09">
                                <span className="fa-stack fa-lg">
                                  <i className="fa fa-circle fa-stack-2x"></i>
                                  <i className="fa-brands fa-twitter fa-stack-1x fa-inverse"></i>
                                </span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    )
}