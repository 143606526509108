import ReactDOM from "react-dom/client";
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";

import {Homepage} from "./feature";

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

const Routing = () => {
    return(
        <Router>
            <Routes>
                <Route element={<Homepage />} path="/" />
            </Routes>
        </Router>
    )
}

root.render(<Routing />);