import {useEffect} from "react";
// @ts-ignore
import Logo from "../../assets/images/logo.png";

export const Navbar = () => {
    useEffect(() => {
        const nav = document.querySelector(".nav");
        const navbarCollapser = document.querySelector(".nav__collapser");

        // @ts-ignore
        navbarCollapser!.onclick = () => {
            nav!.classList.toggle("nav--expanded");
        };
    }, []);

    return(
        <nav className="nav">
            <div className="nav__collapser">
                <i className="fas fa-bars fa-2x"></i>
            </div>
            <div className="nav__brand">
                <img src={Logo} width={200} height="auto" />
            </div>
            <div className="nav__collapsable">
                <a href="#aboutUs" className="text-dark p-2">
                    About us
                </a>
                <a href="#consulting" className="text-dark p-2">
                    Consulting
                </a>
                <a href="#contactUs" className="text-dark p-2">
                    Contact us
                </a>
            </div>
        </nav>
    )
}