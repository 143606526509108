import React from "react";

export const Card = ({title, icon, children, className}: { title: string; icon: React.ReactNode, children: React.ReactNode; className?: string }) => {
    return (
        <div className={`card card-body ${className} text-center text-light`} style={{ minHeight: '12rem' }}>
            {icon}
            <div className="divider" style={{ width: '5rem', backgroundColor: '#3bafed', marginBottom: '1rem', marginTop: '1rem' }}></div>
            <p className="font-weight-bold mb-0">{title}</p>
            {children}
        </div>
    )
}